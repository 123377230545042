import { createRouter, createWebHistory } from 'vue-router'
import DashboardOld from './components/Dashboard.vue'
import Dashboard from './components/stb/Dashboard.vue'
import Profile from './components/stb/Profile.vue'
import Login2fa from './components/Login2fa.vue'

import EthAddresses from './components/stb/EthAddresses.vue'

import Test from './components/stb/Test'

const routes = [
  {
    path: '/trading',
    name: 'trading',
    component: () => import('./components/stb/Trading'),
    meta: {
      title: 'Trading',
      auth: true,
    },
  },
  {
    path: '/trading-view',
    name: 'trading_view',
    component: () => import('./components/dev/TradingView'),
    meta: {
      title: 'Trading View',
      auth: true,
    },
  },
  {
    path: '/emissions',
    name: 'emissions',
    component: () => import('./components/stb/Emissions'),
    meta: {
      title: 'Emissions',
      auth: true,
    }
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('./components/stb/Projects'),
    meta: {
      title: 'Projects',
      auth: true,
    }
  },
  {
    path: '/tokens',
    name: 'tokens',
    component: () => import('./components/stb/Tokens'),
    meta: {
      title: 'Tokens',
      auth: true,
    }
  },
  {
    path: '/capital',
    name: 'capital',
    component: () => import('./components/stb/Capital'),
    meta: {
      title: 'Capital',
      auth: true,
    }
  },
  {
    path: '/pair',
    name: 'Pair',
    component: () => import('./components/stb/Pair'),
    meta: {
      title: 'Pair',
      auth: true,
    }
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () => import('./components/stb/Wallet'),
    meta: {
      title: 'Wallet',
      auth: true,
    }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('./components/stb/Company'),
    meta: {
      title: 'My Companies',
      auth: true,
    }
  },
  {
    path: '/manager-wallets',
    name: 'ManagerWallets',
    component: () => import('./components/manager/ManagerWallets'),
    meta: {
      title: 'Wallets',
      auth: true,
    }
  },
  {
    path: '/manager-company',
    name: 'ManagerCompany',
    component: () => import('./components/manager/ManagerCompany'),
    meta: {
      title: 'Edit Companies',
      auth: true,
    },
  },
  {
    path: '/manager-emission',
    name: 'ManagerEmission',
    component: () => import('./components/manager/ManagerEmission'),
    meta: {
      title: 'Emission',
      auth: true,
    },
  },
  {
    path: '/manager-chains',
    name: 'ManagerChains',
    component: () => import('./components/manager/ManagerChains'),
    meta: {
      title: 'Chains',
      auth: true,
    },
  },
  {
    path: '/manager-nft',
    name: 'ManagerNft',
    component: () => import('./components/manager/ManagerNft'),
    meta: {
      title: 'Market',
      auth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      auth: true,
    },
  },
  {
    path: '/login2fa',
    name: 'login2fa',
    component: Login2fa,
    meta: {
      auth: false,
    },
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      auth: true,
    },
  },
  {
    path: '/eth_addresses',
    name: 'eth_addresses',
    component: EthAddresses,
    meta: {
      title: 'My ETH',
      auth: true,
    },
  },
  {
    path: '/old',
    name: 'dashboardOld',
    component: DashboardOld,
  },
  {
    path: '/test',
    name: 'test',
    component: Test,
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('./components/stb/Transactions'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/email_verify/:hash',
    name: 'EmailVerify',
    component: () => import('./pages/EmailVerify'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/adresses/:hash/validate',
    name: 'Adresses',
    component: () => import('./pages/adresses/_adress/Validate'),
    meta: {
      name: 'ETH Validate',
      auth: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('./pages/Register'),
    meta: {
      title: 'Registration',
      auth: false,
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('./components/stb/Reports'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/market',
    name: 'market',
    component: () => import('./components/stb/Market'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/formlayout',
    name: 'formlayout',
    component: () => import('./components/FormLayoutDemo.vue'),
  },
  {
    path: '/input',
    name: 'input',
    component: () => import('./components/InputDemo.vue'),
  },
  {
    path: '/floatlabel',
    name: 'floatlabel',
    component: () => import('./components/FloatLabelDemo.vue'),
  },
  {
    path: '/invalidstate',
    name: 'invalidstate',
    component: () => import('./components/InvalidStateDemo.vue'),
  },
  {
    path: '/button',
    name: 'button',
    component: () => import('./components/ButtonDemo.vue'),
  },
  {
    path: '/table',
    name: 'table',
    component: () => import('./components/TableDemo.vue'),
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('./components/ListDemo.vue'),
  },
  {
    path: '/tree',
    name: 'tree',
    component: () => import('./components/TreeDemo.vue'),
  },
  {
    path: '/panel',
    name: 'panel',
    component: () => import('./components/PanelsDemo.vue'),
  },
  {
    path: '/overlay',
    name: 'overlay',
    component: () => import('./components/OverlayDemo.vue'),
  },
  {
    path: '/media',
    name: 'media',
    component: () => import('./components/MediaDemo.vue'),
  },
  {
    path: '/menu',
    component: () => import('./components/MenuDemo.vue'),
    children: [
      {
        path: '',
        component: () => import('./components/menu/PersonalDemo.vue'),
      },
      {
        path: '/menu/seat',
        component: () => import('./components/menu/SeatDemo.vue'),
      },
      {
        path: '/menu/payment',
        component: () => import('./components/menu/PaymentDemo.vue'),
      },
      {
        path: '/menu/confirmation',
        component: () => import('./components/menu/ConfirmationDemo.vue'),
      },
    ],
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import('./components/MessagesDemo.vue'),
  },
  {
    path: '/file',
    name: 'file',
    component: () => import('./components/FileDemo.vue'),
  },
  {
    path: '/chart',
    name: 'chart',
    component: () => import('./components/ChartDemo.vue'),
  },
  {
    path: '/misc',
    name: 'misc',
    component: () => import('./components/MiscDemo.vue'),
  },
  {
    path: '/crud',
    name: 'crud',
    component: () => import('./pages/CrudDemo.vue'),
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: () => import('./pages/TimelineDemo.vue'),
  },
  {
    path: '/empty',
    name: 'empty',
    component: () => import('./components/EmptyPage.vue'),
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: () => import('./components/Documentation.vue'),
  },
  {
    path: '/blocks',
    name: 'blocks',
    component: () => import('./components/BlocksDemo.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('./components/IconsDemo.vue'),
  },
  {
    path: '/landing',
    name: 'landing',
    component: () => import('./pages/LandingDemo.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/Login.vue'),
    meta: {
      title: 'Login',
      auth: false,
    },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('./pages/Error.vue'),
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: () => import('./pages/NotFound.vue'),
  },
  {
    path: '/access',
    name: 'access',
    component: () => import('./pages/Access.vue'),
  },
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
})
router.beforeEach((to) => {
  if (to.meta.title) {
    document.title = `${to.meta.title} ` + 'to Autentic'
  } else {
    document.title = 'Autentic '
  }
})

export default router
