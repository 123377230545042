<template>
  <div class="grid">
    <div class="col-12 xl:col-9">
      <div class="card">
        <div class="flex justify-content-between align-items-center mb-5">
          <h5>Структура инвестиционного портфеля</h5>
          <div>
            <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu2.toggle($event)"></Button>
            <Menu ref="menu2" :popup="true" :model="items"></Menu>
          </div>
        </div>
        <DataTable :value="ownerList">
          <Column field="id" header="Id"/>
          <Column field="name" header="Название"/>
          <Column field="mnemonic" header="Обозначение"/>
          <Column field="amount" header="Баланс">
            <template #body='slot'>
              <OutputDecimals :decimals-value='slot.data.amount' :decimals='slot.data.decimals'/>
            </template>
          </Column>
          <Column field="amountBlocked" header="Заблокировано">
            <template #body='slot'>
              <OutputDecimals :decimals-value='slot.data.amountBlocked' :decimals='slot.data.decimals'/>
            </template>
          </Column>
          <Column field="amountFree" header="Доступно">
            <template #body='slot'>
              <OutputDecimals :decimals-value='slot.data.amountFree' :decimals='slot.data.decimals'/>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="col-12 xl:col-3">
      <div class="card">
        <h5>Инвестиционный портфель (динамика изменений)</h5>
        <Chart type="line" :data="lineData" :options="lineOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/AppEventBus';
import ProductService from '../../service/ProductService';
import OutputDecimals from '@/components/edit/OutputDecimals'

export default {
  components: { OutputDecimals },
  data() {
    return {
      products: null,
      ownerList: [],
      tokenList: [],
      lineData: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Стоимость портфеля',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e',
            tension: 0.4
          }
        ]
      },
      items: [
        {label: 'Add New', icon: 'pi pi-fw pi-plus'},
        {label: 'Remove', icon: 'pi pi-fw pi-minus'}
      ],
      lineOptions: null,
    }
  },
  productService: null,
  themeChangeListener: null,
  async mounted() {
    this.productService.getProductsSmall().then(data => this.products = data);

    this.themeChangeListener = (event) => {
      if (event.dark)
        this.applyDarkTheme();
      else
        this.applyLightTheme();
    };
    EventBus.on('change-theme', this.themeChangeListener);

    if (this.isDarkTheme()) {
      this.applyDarkTheme();
    }
    else {
      this.applyLightTheme();
    }

    await this.getTokens();
    await this.getOwners();
  },
  beforeUnmount() {
    EventBus.off('change-theme', this.themeChangeListener );
  },
  created() {
    this.productService = new ProductService();
  },
  methods: {
    async getOwners(){
      try
        {
          const resp = await this.axios.get('api/au_owners');
          const ownerData = resp.data;
          for (const item of ownerData)
          {
            [item.name, item.mnemonic, item.decimals] = this.getTokenInfo(item.auToken);
            this.ownerList.push(item);
          }
        }
      catch (e)
      {
        console.log('error', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'api/au_owners get error',
          group: 'info',
          life: 3000,
        })
      }
    },

    async getTokens()
    {
      try {
        const resp = await this.axios.get("api/au_tokens");
        this.tokenList = resp.data;
      }
      catch (e)
      {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'api/au_tokens get error',
          group: 'info',
          life: 3000,
        })
      }
    },

    getTokenInfo(value){
      let id = value.id;
      //check value iri or object
      if (id !== undefined) return [value.name, value.mnemonic, value.decimals];
      id = value.slice(value.lastIndexOf('/') + 1);
      const token = this.tokenList.find(item => item.id == id);
      if (token === undefined) return ["No name", "-", 0];
      return [token.name ,token.mnemonic, token.decimals];
    },

    formatCurrency(value) {
      return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    },
    isDarkTheme() {
      return this.$appState.darkTheme === true;
    },
    applyLightTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color:  '#ebedef',
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color:  '#ebedef',
            }
          },
        }
      };
    },
    applyDarkTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#ebedef'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#ebedef'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#ebedef'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      };
    },
  }
}
</script>
