<template>
  <div>
    <div class='container'>
      <label for='NameRu' class='BoldText'><b>Название компании (на русском)(*)</b></label>
      <label for='NameEn' class='LabelText'>Название компании (на английском)</label>
    </div>

    <div class="p-inputgroup">
      <InputText id='NameRu' placeholder="Название компании (на русском)" v-model="nameRus" />
      <InputText id='NameEn' placeholder="Название компании (на английском)" v-model="nameEng" />
    </div>

    <div class='container'>
      <label for='DescrRu' class='LabelText'>Сфера деятельности (рус.)</label>
      <label for='DescrEn' class='LabelText'>Сфера деятельности (анг.)</label>
    </div>

    <div class="p-inputgroup">
      <Textarea
        id='DescrRu'
        placeholder="Сфера деятельности (рус.)"
        :autoResize="true"
        v-model="descriptionRus"
      />
      <Textarea
        id='DescrEn'
        placeholder="Сфера деятельности (анг.)"
        :autoResize="true"
        v-model="descriptionEng"
      />
    </div>

    <div class='container'>
      <label for='AdrRu' class='BoldText'><b>Адрес (рус.)(*)</b></label>
      <label for='AdrEn' class='LabelText'>Адрес (анг.)</label>
    </div>

    <div class="p-inputgroup">
      <InputText id='AdrRu' placeholder="Адрес (рус.)" v-model="addressRus" />
      <InputText id='AdrEn' placeholder="Адрес (анг.)" v-model="addressEng" />
    </div>

    <div class='container'>
      <label for='Country' class='BoldText'><b>Страна(*)</b></label>
      <label for='url' class='LabelText'>URL сайта компании</label>
    </div>

    <div class="p-inputgroup">
      <Dropdown
        id='Country'
        v-model="selectedCountry"
        :options="countries"
        optionLabel="name"
        :filter="true"
        placeholder="Страна"
        :showClear="true"
      >
        <template #option="slotProps">
          <div class="country-item">
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </Dropdown>

      <InputText
        id="url"
        placeholder="URL сайта компании"
        aria-describedby="url"
        v-model="url"
      />
    </div>
    <small v-if="btnCancel" class="block m-1"
    >Last selected country:
      <b>{{ this.companiesEdit.country.name }}</b></small
    >
    <div class="field-checkbox my-3">
      <Checkbox id="isVerifyRequest" v-model="isVerifyRequest" :binary="true" />
      <label for="isVerifyRequest"
      >Запрос верификации компании. Редактирование данных будет недоступно</label
      >
    </div>
    <div v-if="this.$auth.check('manager')" class="field-checkbox">
      <Checkbox id="isVerified" v-model="isVerified" :binary="true" />
      <label for="isVerified">Подтверждение компании</label>
    </div>
    <small
      class="block my-1 p-error"
      v-if="
        (nameEng !== '' && !$filters.languageEN(nameEng))||
        (descriptionEng !== '' && !$filters.languageEN(descriptionEng)) ||
        (addressEng !== '' && !$filters.languageEN(addressEng))
      "
    >Анголязычная информация должна быть только на английском</small
    >
    <small for="url" class="block my-1 p-error" v-if="url !== '' && !$filters.correctURL(url)"
    >Некорректный URL</small
    >
    <small class="block my-1 p-error" v-if="!allField"
    >Заполните все обязательные поля (со звездочкой *)</small
    >
    <Button
      v-if="!btnCancel"
      :disabled="!allField"
      :label="!isVerifyRequest ? 'Сохранить как черновик' : 'Добавить компанию'"
      class="p-button-primary"
      @click="createCompany"
    />
    <Button
      v-if="btnCancel"
      :disabled="!allField"
      :label="!isVerifyRequest ? 'Сохранить как черновик' : 'Сохранить изменения'"
      class="p-button-primary"
      @click="updateCompany"
    />
    <Button
      v-if="btnCancel"
      label="Cancel"
      class="p-button-danger"
      @click="cancelEdit"
    />
  </div>
</template>

<script>
export default {
  name: 'EditCompany',
  props: {
    countries: {
      type: Object,
    },
    companies: {
      type: Object,
    },
    companiesEdit: {
      type: Object,
    },
    btnCancel: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.SetField();
  },
  data() {
    return {
      nameRus: '',
      nameEng: '',
      descriptionRus: '',
      descriptionEng: '',
      addressRus: '',
      addressEng: '',
      country: '',
      selectedCountry: null,
      url: '',
      isVerifyRequest: false,
      isVerified: false,
    }
  },
  methods: {
    async createCompany() {
      console.log('create')
      const params = {
        nameRus: this.nameRus,
        nameEng: this.nameEng,
        descriptionRus: this.descriptionRus,
        descriptionEng: this.descriptionEng,
        addressRus: this.addressRus,
        addressEng: this.addressEng,
        country: `/api/countries/${this.selectedCountry.id}`,
        url: this.url,
        isVerifyRequest: this.isVerifyRequest,
      }
      console.log('params', params)
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      try {
        const resp = await this.axios.post('api/companies', params, headers)
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Company created',
          group: 'info',
          life: 3000,
        })
        console.log('resp', resp)
        this.clearFields()
        this.$emit('getCompanies')
      } catch (e) {
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Company not created',
          group: 'info',
          life: 3000,
        })
      }
    },
    async updateCompany() {
      console.log('update')
      const params = {
        nameRus: this.nameRus,
        nameEng: this.nameEng,
        descriptionRus: this.descriptionRus,
        descriptionEng: this.descriptionEng,
        addressRus: this.addressRus,
        addressEng: this.addressEng,
        country: `/api/countries/${this.selectedCountry.id}`,
        url: this.url,
        isVerifyRequest: this.isVerifyRequest,
        isVerified: this.isVerified,
      }
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      try {
        const resp = await this.axios.put(
          `api/companies/${this.itemId}`,
          params,
          headers
        )
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Company updated',
          group: 'info',
          life: 3000,
        })
        console.log('resp', resp)
        this.clearFields()
        this.$emit('getCompanies')
      } catch (e) {
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Company not updated',
          group: 'info',
          life: 3000,
        })
      }
    },
    cancelEdit() {
      console.log('cancel')
      this.clearFields()
      this.$emit('cancelEdit')
    },
    clearFields() {
      console.log('clear')
      ;(this.nameRus = ''),
        (this.nameEng = ''),
        (this.descriptionRus = ''),
        (this.descriptionEng = ''),
        (this.addressRus = ''),
        (this.addressEng = ''),
        (this.country = ''),
        (this.url = ''),
        (this.isVerifyRequest = false)
    },

    SetField(){
      if(this.companiesEdit)
      {
        this.nameRus = this.companiesEdit.nameRus
        this.nameEng = this.companiesEdit.nameEng
        this.descriptionRus = this.companiesEdit.descriptionRus
        this.descriptionEng = this.companiesEdit.descriptionEng
        this.addressRus = this.companiesEdit.addressRus
        this.addressEng = this.companiesEdit.addressEng
        this.url = this.companiesEdit.url
        this.isVerifyRequest = this.companiesEdit.isVerifyRequest
        this.isVerified = this.companiesEdit.isVerified
        this.selectedCountry = this.countries[this.companiesEdit.country.id - 1]
      }
    },
  },
  computed: {
    allField() {
      return (
        this.nameRus.length > 1 &&
        this.addressRus.length > 1 &&
        this.selectedCountry !== null
      )
    },
  },
}
</script>

<style scoped>
.p-inputtext,
.p-component {
  margin: 2px;
}

.container{
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
}
.LabelText{
  flex-basis: 50%;
  font-style: normal;
  font-size: smaller;
}
.BoldText{
  flex-basis: 50%;
  font-style: normal;
  font-size: smaller;
}
</style>
